<template>
    <div class="customer-information">
        <div class="add-btn-left">
            <el-button type="primary" @click="addBtn()"> <i class="el-icon-plus"></i>添加客户信息 </el-button>
        </div>
        <el-table
            :data="customerTable"
            style="width: 100%; flex: 1"
            height="1%;border: 1px solid #EEE;"
            size="medium"
            :header-cell-style="{ fontWeight: 'normal', color: '#666', background: '#eee', fontSize: '16px', height: '60px' }"
            class="customTable"
            :cell-style="{ fontSize: '12px', color: '#333', height: '70px' }"
        >
            <el-table-column prop="name" label="客户信息名称"></el-table-column>
            <el-table-column label="操作" align="center" width="200">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="editCustomer(scope.row)">编辑</el-link>
                        <el-link type="danger" :underline="false" @click="deleteCustomer(scope.row)">删除</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            class="pages-center"
            :current-page="customerPages.currentPageNum"
            :page-size="customerPages.eachPageNum"
            layout="prev, pager, next, jumper"
            :total="customerPages.total"
            @current-change="customerCurrentChange"
        >
        </el-pagination>
    </div>
</template>

<script>
import { customerInfoGetModuleList, customerInfoDel } from "@/utils/apis.js";
export default {
    name: "CustomerInformation",
    data() {
        return {
            customerTable: [],
            //分页
            customerPages: {
                currentPageNum: 1,
                eachPageNum: 10,
                total: 0,
            },
        };
    },
    mounted() {
        this.getCustomerList();
    },
    methods: {
        // 获取客户信息列表
        getCustomerList() {
            let params = {
                paging: 1,
                pageSize: this.customerPages.eachPageNum,
                page: this.customerPages.currentPageNum,
            };
            customerInfoGetModuleList(params).then((res) => {
                this.customerTable = res.data.list;
                this.customerPages.total = res.data.total;
            });
        },
        //添加客户信息
        addBtn() {
            this.$router.push({
                path: "/practice/addCustomerInformation",
            });
        },
        // 编辑
        editCustomer(row) {
            this.$router.push({
                path: "/practice/addCustomerInformation",
                query: {
                    id: row.customer_info_group_id,
                },
            });
        },
        //删除
        deleteCustomer(row) {
            let param = {
                customer_info_group_id: row.customer_info_group_id,
            };
            this.$confirm(`是否删除该信息，删除将无法恢复`, "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "green-message-box",
                type: "warning",
            })
                .then(() => {
                    customerInfoDel(param)
                        .then((res) => {
                            this.$message({
                                message: res.msg,
                                type: "success",
                                duration: 1000,
                                onClose: () => {
                                    this.getCustomerList();
                                },
                            });
                        })
                        .catch((err) => {
                            console.log("err", err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 切换分页
        customerCurrentChange(val) {
            this.customerPages.currentPageNum = val;
            this.getCustomerList();
        },
    },
};
</script>

<style scoped lang="scss">
.customer-information {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .add-btn-left {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 20px;
    }
}
</style>
